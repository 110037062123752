@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background-color: #04232e;
  color: #ffffff;
  overflow-x: hidden;
  position: relative;
}
body::before {
  content: "";
  position: absolute;
  background: url("/src/assets/images/dashbg.png") no-repeat center;
  height: 100%;
  background-size: auto;
  width: 100%;
  top: -100px;
  z-index: -1;
}
a {
  color: #00ffcc;
  text-decoration: none;
}
a:hover {
  color: #ff437f;
  text-decoration: none;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #00ffcc;
}
img.brand_logo {
  width: 190px;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 41px;
  left: 0;
  right: 0;
  background: #031c26;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  z-index: 10;
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.primary_btn {
  text-transform: uppercase;
  box-shadow: 0 0 12px 1px rgb(255 138 0 / 30%);
  z-index: 1;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  background: linear-gradient(196deg, #06c8b2 -3%, #10113d 39%) padding-box,
    linear-gradient(to right, #3ffaf6, #06c8b2) border-box;
  border-radius: 10px;
  border: 1px solid transparent !important;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 20px;
  box-shadow: inset 0 0 27px 0px #07c8b2, 0 0 17px 1px #06c8b2;
  color: #fff;
}
.primary_btn:hover {
  background: linear-gradient(186deg, #06c8b2 -3%, #10113d 39%) padding-box,
    linear-gradient(to left, #3ffaf6, #06c8b2) border-box;
  box-shadow: inset 0 0 27px 0px #07c8b2, 0 0 17px 1px #06c8b2;
}
/* .navbar_right button+button {
  margin-left: 20px;
} */
.navbar_right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.offcanvas {
  background: #031c26;
}
.offcanvas-header button.btn-close {
  background: url("../src/assets/images/close_icon.png") no-repeat;
  width: 30px;
  height: 30px;
}
.my_dash_menu {
  background: linear-gradient(
    0deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
}
.my_dash_menu li a {
  color: #fff;
}
.my_dash_menu li a:hover {
  background-color: #3ffaf6;
}
.page_header {
  padding: 20px 0;
}
.page_header h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.page_header h1 span {
  color: #04daca;
}
.page_header h5 {
  color: #04daca;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}
.custom_list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom_list li {
  position: relative;
  padding-bottom: 20px;
  padding-left: 30px;
}
.custom_list li::before {
  content: "";
  background: url("./assets/images/list_icon.png") no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 3px;
}
section {
  padding: 50px 0;
}
.dark_bg {
  background: rgba(5, 42, 56, 0.7);
}
.referral_div {
  display: flex;
  align-items: center;
}
.referral_div img {
  margin-right: 25px;
}
.referral_div h3 {
  font-size: 20px;
  font-weight: 700;
}
.referral_div h4 {
  font-size: 18px;
  font-weight: 300;
}
.referral_div h4 span {
  font-size: 36px;
  font-weight: 700;
  color: #05d9cb;
}
.main_title {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.main_para {
  font-size: 18px;
  font-weight: 300;
}
.referral_note {
  background: #073544;
  border-radius: 20px;
  padding: 30px;
  margin-top: 50px;
}
.std_slider_box {
  background: rgb(6, 48, 64);
  background: linear-gradient(
    45deg,
    rgba(6, 48, 64, 1) 0%,
    rgba(6, 48, 64, 1) 50%,
    rgba(5, 217, 203, 1) 100%
  );
  text-align: center;
  border-radius: 20px;
  padding: 30px 15px;
  border: 1px solid #063040;
  transition: 0.2s all;
  margin: 10px;
}
.std_slider_box:hover {
  border: 1px solid #05d9cb;
}
.std_slider_box > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.std_slider_box > div h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}
.std_slider_box h2 {
  color: #05d9cb;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}
.std_slider_box .std_join_btn {
  font-size: 16px;
  font-weight: 600;
  padding: 6px 25px;
}
.std_slider .slick-next {
  right: -15px;
}
.std_slider .slick-prev {
  left: -20px;
}
.std_slider .slick-prev:before,
.std_slider .slick-next:before {
  font-size: 24px;
  color: #05c6bb;
}
.std_slider .slick-dots li button:before {
  font-size: 16px;
  color: #fff;
  opacity: 0.35;
}
.slick-dots li.slick-active button:before {
  color: #ffff !important;
}
.earning_section h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.earning_section h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 20px 0;
}
.earning_section h4 span {
  font-weight: 700;
  color: #05d9cb;
}
.earning_section .main_para_italic {
  font-size: 16px;
  font-style: italic;
}
.earning_section .nav-tabs {
  border: 0;
  margin-bottom: 15px;
}
.earning_section .nav-link {
  border-radius: 8px;
  color: #fff;
  padding: 8px 30px;
  margin-right: 15px;
  margin-bottom: 10px;
  background: linear-gradient(
    45deg,
    rgba(6, 48, 64, 1) 0%,
    rgba(6, 48, 64, 1) 50%,
    rgba(5, 217, 203, 1) 100%
  );
  border: 1px solid #05d9cb;
}
.earning_section .nav-link.active,
.earning_section .nav-link:hover {
  box-shadow: inset 0 0 27px 0px #07c8b2, 0 0 10px 1px #06c8b2;
  background: linear-gradient(196deg, #06c8b2 -3%, #10113d 39%) padding-box,
    linear-gradient(to right, #3ffaf6, #06c8b2) border-box;
  color: #fff;
  border-color: #05d9cb;
}
.earning_section .dropdown-toggle {
  background: #073544 !important;
  border: 1px solid #205e72;
  padding: 7px 20px;
}
.earning_section .dropdown-toggle::after {
  margin-left: 0.755em;
}
.earning_section .dropdown-menu {
  background: #073544;
}
.earning_section .dropdown-item {
  color: #fff;
}
.earning_section .dropdown-item:hover {
  background: rgba(0, 0, 0, 0.15);
}
.faq_accordion {
  margin-top: 50px;
}
.faq_accordion .accordion {
  border: none;
}
.faq_accordion .accordion-item {
  background: transparent;
}
.faq_accordion .accordion-button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #04daca;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  align-items: flex-start;
}
.faq_accordion .accordion-button span {
  max-width: 90%;
}
.faq_accordion .accordion-collapse.collapse {
  background: transparent;
}
.faq_accordion .accordion-item {
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  overflow: hidden;
  padding: 10px;
  border-bottom: 1px solid #164e59;
}
.faq_accordion .accordion-body {
  color: #fff;
  font-size: 18px;
  padding: 0;
}
.faq_accordion .accordion-button:not(.collapsed) {
  color: #04daca;
}
.faq_accordion .accordion-button::after {
  content: "";
  background: url("./assets/images/faq_arrow.png");
  width: 9px;
  height: 15px;
}
.faq_accordion .accordion-button:not(.collapsed)::after {
  transform: rotate(90deg);
}
.faq_accordion .accordion-item:last-child {
  border-bottom: 0;
}
.above_info_box {
  background: #084459;
  padding: 10px 0;
}
.above_info_box ::-webkit-scrollbar {
  width: 1px;
  height: 4px;
}
.above_info_box .above_box_flx {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  padding: 0 10px;
}
.above_info_box .above_box_flx div {
  display: flex;
  flex-wrap: nowrap;
}
.above_info_box .above_box_flx div p {
  font-size: 14px;
  color: #fff;
  margin-right: 20px;
  margin-bottom: 0;
  white-space: nowrap;
}
.above_info_box .above_box_flx div span {
  color: #03cdb9;
  font-size: 14px;
}
.above_info_box.above_fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

.footer {
  background-color: #041f29;
  padding: 30px 0 20px;
}
.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_panel_left p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
}
.footer_panel_left a {
  font-size: 14px;
  color: #975fff;
  white-space: normal;
  word-break: break-all;
}
.footer_social_links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer_social_links li {
  margin: 0 5px;
}
.footer_social_links li a {
  background: #04daca;
  color: #041f29;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-decoration: none;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  background: #021217;
  color: #04daca;
}
input.primary_inp {
  background: #041f29 !important;
  border: 1px solid #041f29 !important;
  box-shadow: none !important;
  padding: 12px;
  color: #fff !important;
}
.wallet_address_btn {
  display: inline-block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: inherit;
  transition: all 0.2s;
  width: 180px;
}
.wallet_address_txt {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  line-height: 12px;
}
.wallet_modal {
  margin-bottom: 15px;
}
.wallet_modal > div {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
.wallet_modal > div label:first-child {
  width: 30%;
  color: #43c6f5;
}
i.fas.fa-copy {
  cursor: pointer;
}

/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(
    180deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
  border: 1px solid #3ffaf6;
  box-shadow: inset 0 0 9px 8px rgba(6, 200, 177, 0.35),
    0 0 7px 1px rgba(6, 200, 177, 0.65);
  border-radius: 20px;
}
.primary_modal .modal-header {
  background: linear-gradient(
    180deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
  border-bottom: none;
  padding: 20px 24px 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.connect_wallet_button {
  border: 2px solid #3ffaf6;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}
.connect_wallet_button:hover {
  border-color: #042430;
}
.primary_modal .modal-header h5,
.standard_wrapper_title h2 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  color: #fff;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.primary_modal .btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("./assets/images/close_icon.png") no-repeat;
  box-shadow: none !important;
  opacity: 1;
  top: -12px;
  position: absolute;
  right: -20px;
}
.primary_modal ::placeholder {
  color: #a8a8a8;
}
.standard_modal .modal-body {
  padding: 0;
}
.standard_wrapper {
  display: flex;
  align-items: flex-start;
}
.standard_wrapper_left,
.standard_wrapper_right {
  width: 50%;
}
.standard_wrapper_left {
  border-right: 1px solid #3df7f2;
}
.standard_wrapper_title {
  background: linear-gradient(
    180deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
  padding: 20px 24px 15px;
  text-align: center;
}
.standard_wrapper_left .standard_wrapper_title {
  border-radius: 20px 0 0 0;
}
.standard_wrapper_right .standard_wrapper_title {
  border-radius: 0 20px 0 0;
}
.standard_wrapper_body {
  padding: 30px 20px;
}
.standard_circle {
  border-radius: 100px;
  height: 29px;
  width: 29px;
  margin-right: 8px;
  max-width: 29px;
}
.standard_bronze {
  background: #f2b999;
  background: linear-gradient(0deg, #f2b999, #a66641 32%, #59341e 54%, #f2b077);
  border: 1px solid #ebaa73;
}
.standard_silver {
  background: #f5f5f5;
  background: linear-gradient(0deg, #f5f5f5, #f4ebeb 32%, #b0b0b0 54%, #b5b5b5);
  border: 1px solid #e5e5e5;
}
.standard_gold {
  background: #c07e2c;
  background: linear-gradient(0deg, #c07e2c, #fcfe88 32%, #c07e2b 54%, #faf884);
  border: 1px solid #cc983f;
}
.standard_platinum {
  background: #d3d4d6;
  background: linear-gradient(0deg, #d3d4d6, #a2a3a7 32%, #6b6e74 54%, #d2d2d4);
  border: 1px solid #c6c7c9;
}
.standard_diamond {
  background: #c7e1ee;
  background: linear-gradient(0deg, #c7e1ee, #f1f6fc 32%, #95c2d9 54%, #c7e1ee);
  border: 1px solid #f4f7ff;
}
.standards_list_row {
  display: flex;
  align-items: center;
  background: #073242;
  padding: 18px 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.standards_list_row h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.standards_list_row h3 span {
  font-weight: 400;
  margin-left: 10px;
}
.std_join_btn {
  margin-left: auto;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  background: linear-gradient(196deg, #06c8b2 -3%, #10113d 39%) padding-box,
    linear-gradient(to right, #3ffaf6, #06c8b2) border-box;
  border-radius: 8px;
  border: 1px solid transparent !important;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 15px;
  box-shadow: inset 0 0 27px 0px #07c8b2, 0 0 17px 1px #06c8b2;
  color: #fff;
}

.std_join_btn_pop {
  margin-left: auto;
  /* text-transform: uppercase; */
  z-index: 1;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  background: linear-gradient(196deg, #06c8b2 -3%, #10113d 39%) padding-box,
    linear-gradient(to right, #3ffaf6, #06c8b2) border-box;
  border-radius: 8px;
  border: 1px solid transparent !important;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 15px;
  box-shadow: inset 0 0 27px 0px #07c8b2, 0 0 17px 1px #06c8b2;
  color: #fff;
}
.std_join_btn_pop:hover {
  z-index: 1;
  background: linear-gradient(196deg, #06c8b2 -3%, #10113d 39%) padding-box,
    linear-gradient(to right, #07e9e9, #53a39a) border-box;
  box-shadow: inset 0 0 27px 0px #03f7da, 0 0 17px 1px #02635b;
  opacity: 0.3px;
}

.thumb-vertical {
  background: #3df7f2;
  border-radius: 10px;
}
.standards_list {
  margin-right: 15px;
}

/* Dashboard Css */
.dash_nav_wrap .navbar-sticky--moved-up {
  top: 0 !important;
}
.dashbg {
  position: relative;
}
/* .dashbg::before {
  content: "";
  position: absolute;
  background: url("/src/assets/images/dashbg.png") no-repeat center;
  height: 100%;
  background-size: auto;
  width: 100%;
  top: -100px;
  z-index: -1;
} */
.inner_wrapper {
  min-height: 90vh;
  padding: 80px 0;
  position: relative;
}
.dashboard_panel {
  background: rgba(3, 28, 38, 0.5);
  padding: 30px;
  border-radius: 0 0 20px 20px;
}
.title_flex {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.inner_title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.dashboard_panel_bronze .inner_title {
  color: #ebaa73;
}
.dashboard_wrapper {
  display: flex;
  align-items: flex-start;
}
.dashboard_wrapper_left {
  margin-right: 30px;
  width: calc(100% - 400px);
}
.dashboard_wrapper_right {
  width: 370px;
}
.dashboard_panel_bronze .std_box {
  background: #f2b999;
  background: linear-gradient(0deg, #f2b999, #a66641 32%, #59341e 54%, #f2b077);
  border: 2px solid #ebaa73;
  box-shadow: 0 0 8px 1px rgba(93, 55, 32, 0.7);
}
.std_box {
  align-items: center;
  border-radius: 10px;
  display: flex;
}
.std_box h2,
.std_box h3 {
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}
.std_box h2 {
  font-size: 18px;
  font-weight: 700;
  min-width: 130px;
  padding: 19px 18px;
  width: 50%;
}
.std_box h2 span {
  font-size: 18px;
  font-weight: 700;
  display: block;
}
.std_box h3 {
  font-size: 18px;
  font-weight: 700;
  padding: 12px 5px;
  position: relative;
}
.std_box h3:nth-child(2) {
  font-size: 16px;
}
.std_box div {
  position: relative;
  width: 50%;
}
.dashboard_panel_bronze .std_box div:before {
  background: #ebaa73;
}
.std_box div:after,
.std_box div:before {
  content: "";
  height: 100%;
  position: absolute;
  width: 1px;
}
.dashboard_panel_bronze .std_box div:after,
.dashboard_panel_bronze .std_box h3:nth-child(2):before {
  background: #5e351e;
}
.std_box div:before {
  right: 1px;
}
.std_box div:after {
  right: 0;
  top: 0;
}
.dashboard_panel_bronze .std_box h3:first-child:after {
  background: #ebaa73;
}
.std_box h3:first-child:after {
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.std_box h3:nth-child(2):before {
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.dashboard_wrapper_left_top,
.dashboard_wrapper_left_middle,
.dashboard_wrapper_left_bottom {
  display: flex;
  align-items: center;
}
.dashboard_wrapper_left_middle {
  margin: 30px 0;
}
.dashboard_wrapper_inner_left {
  width: 265px;
  margin-right: 30px;
}
.dashboard_wrapper_inner_right {
  /* width: calc(100% - 295px); */
  width: 540px;
}
.green_box_wrap {
  background: rgb(11, 97, 128);
  background: linear-gradient(
    0deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
  border: 2px solid #3ffaf6;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: inset 0 0 9px 8px rgba(6, 200, 177, 0.35),
    0 0 7px 1px rgba(6, 200, 177, 0.65);
}
.green_box_wrap_title {
  text-align: center;
  padding: 20px;
  background: rgb(11, 97, 128);
  background: linear-gradient(
    180deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
}
.green_box_wrap_title h2 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}
.green_box_wrap_title h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}
.dashboard_panel_bronze .green_box_wrap_title h2,
.dashboard_panel_bronze .green_box_wrap_title h3,
.dashboard_panel_bronze .purple_box_wrap_title h3,
.dashboard_panel_bronze .purple_box_wrap_title h2 {
  color: #ebaa73;
}
.green_box_wrap_body {
  min-height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.green_box_wrap_body h3 {
  text-align: center;
  font-size: 34px;
  font-weight: 700;
  margin: 0;
}
.green_box_wrap_footer {
  padding: 20px;
  background: rgb(11, 97, 128);
  background: linear-gradient(
    180deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
}
.rewards_step {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rewards_step > div {
  display: flex;
  align-items: center;
}
.rewards_step > div p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
}
.rewards_step > div span {
  font-size: 18px;
  font-weight: 600;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
  border: 1px solid #3ffaf6;
  border-radius: 5px;
  margin-left: 15px;
  position: relative;
}
.rewards_step > div span::after {
  content: "";
  background: #1a8eb8;
  width: 1px;
  height: 28px;
  position: absolute;
  right: -18px;
}
.rewards_step > div:last-child span::after {
  display: none;
}
.rewards_step_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
}
.rewards_step_list li {
  position: relative;
  width: 24%;
  color: #1a8eb8;
}
.rewards_step_list li::before {
  content: "";
  background: #1a8eb8;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 12px;
  left: -53px;
}
.rewards_step_list li:first-child:before {
  display: none;
}
.dashboard_panel_bronze .rewards_step_list li.active {
  color: #ebaa73;
}
.dashboard_panel_bronze .rewards_step_list li.active::before {
  background: #ebaa73;
}
.rewards_step_list li.active::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 55px;
  top: 5px;
  color: #153037;
  font-size: 10px;
}
.green_box_flex {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}
.green_box_flex .green_box_wrap {
  width: 50%;
}
ul.rewards_step_list.rewards_step_list_small {
  flex-direction: unset;
  margin-top: 15px;
}
.rewards_step_list_small.rewards_step_list li {
  width: 50% !important;
  height: initial;
}
.rewards_step_list.rewards_step_list_small li::before {
  left: -42px !important;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 12px;
  left: -53px;
}
.rewards_step_list_small li.active::after {
  left: 45px !important;
}
.reward_small_box_wrap {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  gap: 30px;
}
.reward_small_box {
  background: linear-gradient(
    180deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
  border: 1px solid #3ffaf6;
  width: 85px;
  height: 80px;
  border-radius: 10px;
}
.reward_small_box h2 {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #1a8eb8;
  padding: 5px;
}
.reward_small_box > div {
  height: calc(100% - 28px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.reward_small_box h3 {
  font-size: 14px !important;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}
.reward_small_box h3 span {
  display: block;
  font-weight: 300;
}
ul.rewards_step_list.rewards_step_list_small {
  margin-bottom: 15px;
}
.purple_box_wrap {
  background: rgb(113, 57, 216);
  background: linear-gradient(
    180deg,
    rgba(113, 57, 216, 1) -60%,
    rgba(31, 5, 77, 1) 100%
  );
  border: 2px solid #3ffaf6;
  border-radius: 20px;
  box-shadow: inset 0 0 9px 8px rgba(6, 200, 177, 0.35),
    0 0 7px 1px rgba(6, 200, 177, 0.65);
  overflow: hidden;
}
.purple_box_wrap_title {
  text-align: center;
  padding: 20px 10px;
  background: rgb(11, 97, 128);
  background: linear-gradient(
    180deg,
    rgba(113, 57, 216, 1) 0%,
    rgba(31, 5, 77, 1) 100%
  );
}
.purple_box_wrap_title h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}
.purple_box_wrap_title h2 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}
.purple_box_wrap_body {
  text-align: center;
  padding: 20px;
}
.purple_box_wrap_body h4 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  margin-right: 10px;
}
.purple_box_wrap_body .secondary_btn {
  margin-top: 20px;
}
.secondary_btn {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  min-width: 120px;
  padding: 7px 15px;
  text-transform: uppercase;
  transition: all 0.2s;
  border-radius: 10px;
}
.secondary_btn_large {
  font-size: 20px;
}
.dashboard_panel_bronze .secondary_btn {
  background: #f2b999;
  background: linear-gradient(
    0deg,
    #f2b999 0%,
    #a66641 35%,
    #59341e 70%,
    #f2b077 100%
  );
  border: 1px solid #ebaa73;
}
.dashboard_panel_bronze .secondary_btn:hover {
  background: linear-gradient(
    180deg,
    #f2b999 0%,
    #a66641 35%,
    #59341e 70%,
    #f2b077 100%
  );
}
.green_box_wrap_body_padding {
  padding: 20px;
}
.progress_wrap {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}
.dashboard_panel_bronze .progress_wrap .progress-bar-striped {
  background-color: #804a00;
}
.progress_wrap .progress {
  background: #0a343f;
  height: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.progress_bar_note {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0;
}
.purple_box_wrap_body_pad30 {
  padding: 30px;
}
.todo_list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.todo_list li div {
  border: 2px solid #612fbd;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  width: 100%;
  position: relative;
  font-size: 16px;
  font-weight: 500;
}
.todo_list li div small {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
}
.todo_list li {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}
.todo_list li::after {
  content: "";
  background: #612fbd;
  width: 1px;
  height: 46px;
  position: absolute;
  left: 17px;
  top: 42px;
}
.todo_list li:last-child::after {
  display: none;
}
.todo_list li i.fa-circle {
  color: #612fbd;
  font-size: 14px;
  border: 1px solid #612fbd;
  border-radius: 100%;
  padding: 9px;
  margin-right: 12px;
}
.todo_list li div::before {
  content: "";
  background: #612fbd;
  width: 12px;
  height: 2px;
  position: absolute;
  left: -14px;
}
.todo_list li.completed_todo::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 12px;
  top: 18px;
  color: #153037;
  font-size: 10px;
}
.todo_list li.completed_green,
.todo_list li.completed_green i.fa-circle {
  color: #66ff66;
}
.todo_list li.completed_red,
.todo_list li.completed_red i.fa-circle {
  color: #ff6666;
}
/* Silver Dashboard */
.dashboard_panel_silver .inner_title {
  color: #e5e5e5;
}
.dashboard_panel_silver .std_box {
  background: linear-gradient(0deg, #f5f5f5, #f4ebeb 35%, #b0b0b0 70%, #b5b5b5);
  border: 2px solid #e5e5e5;
  box-shadow: 0 0 8px 1px rgba(56, 60, 69, 0.7);
  color: #595959;
}
.dashboard_panel_silver .std_box div:before,
.dashboard_panel_silver .std_box h3:first-child:after {
  background: #b5b5b5;
}
.dashboard_panel_silver .std_box div:after,
.dashboard_panel_silver .std_box h3:nth-child(2):before {
  background: #e5e5e5;
}
.dashboard_panel_silver .green_box_wrap_title h2,
.dashboard_panel_silver .green_box_wrap_title h3,
.dashboard_panel_silver .purple_box_wrap_title h3,
.dashboard_panel_silver .purple_box_wrap_title h2 {
  color: #e5e5e5;
}
.dashboard_panel_silver .rewards_step_list li.active {
  color: #e5e5e5;
}
.dashboard_panel_silver .rewards_step_list li.active::before {
  background: #e5e5e5;
}
.dashboard_panel_silver .secondary_btn {
  background: #f5f5f5;
  background: linear-gradient(0deg, #f5f5f5, #f4ebeb 35%, #b0b0b0 70%, #b5b5b5);
  border: 1px solid #e5e5e5;
  color: #595959;
}
.dashboard_panel_silver .secondary_btn:hover {
  background: linear-gradient(
    180deg,
    #f5f5f5,
    #f4ebeb 35%,
    #b0b0b0 70%,
    #b5b5b5
  );
}
.dashboard_panel_silver .progress_wrap .progress-bar-striped {
  background-color: #b5b5b5;
}

/* Gold Dashboard */
.dashboard_panel_gold .inner_title {
  color: #e5e5e5;
}
.dashboard_panel_gold .std_box {
  background: #c5a34f;
  background: linear-gradient(0deg, #c5a34f, #ffde8a 35%, #c5a34f 70%, #ffde8a);
  border: 2px solid #e2c479;
  box-shadow: 0 0 8px 1px rgba(154, 94, 21, 0.7);
  color: #73460e;
}
.dashboard_panel_gold .std_box div:before,
.dashboard_panel_gold .std_box h3:first-child:after {
  background: #e0c15c;
}
.dashboard_panel_gold .std_box div:after,
.dashboard_panel_gold .std_box h3:nth-child(2):before {
  background: #f4e979;
}
.dashboard_panel_gold .green_box_wrap_title h2,
.dashboard_panel_gold .green_box_wrap_title h3,
.dashboard_panel_gold .purple_box_wrap_title h3,
.dashboard_panel_gold .purple_box_wrap_title h2 {
  color: #e2c479;
}
.dashboard_panel_gold .rewards_step_list li.active {
  color: #e2c479;
}
.dashboard_panel_gold .rewards_step_list li.active::before {
  background: #e2c479;
}
.dashboard_panel_gold .secondary_btn {
  background: #c5a34f;
  background: linear-gradient(0deg, #c5a34f, #ffde8a 35%, #c5a34f 70%, #ffde8a);
  border: 1px solid #e2c479;
  color: #73460e;
}
.dashboard_panel_gold .secondary_btn:hover {
  background: linear-gradient(
    180deg,
    #c5a34f,
    #ffde8a 35%,
    #c5a34f 70%,
    #ffde8a
  );
}
.dashboard_panel_gold .progress_wrap .progress-bar-striped {
  background-color: #dcba57;
}

/* Platinum Dashboard */
.dashboard_panel_platinum .inner_title {
  color: #e5e5e5;
}
.dashboard_panel_platinum .std_box {
  background: #d3d4d6;
  background: linear-gradient(0deg, #d3d4d6, #a2a3a7 35%, #6b6e74 70%, #d2d2d4);
  border: 2px solid #c6c7c9;
  box-shadow: 0 0 8px 1px rgba(56, 60, 69, 0.7);
  color: #f0f5ff;
}
.dashboard_panel_platinum .std_box div:before,
.dashboard_panel_platinum .std_box h3:first-child:after {
  background: #c6c7c9;
}
.dashboard_panel_platinum .std_box div:after,
.dashboard_panel_platinum .std_box h3:nth-child(2):before {
  background: #6b6e74;
}
.dashboard_panel_platinum .green_box_wrap_title h2,
.dashboard_panel_platinum .green_box_wrap_title h3,
.dashboard_panel_platinum .purple_box_wrap_title h3,
.dashboard_panel_platinum .purple_box_wrap_title h2 {
  color: #c6c7c9;
}
.dashboard_panel_platinum .rewards_step_list li.active {
  color: #c6c7c9;
}
.dashboard_panel_platinum .rewards_step_list li.active::before {
  background: #c6c7c9;
}
.dashboard_panel_platinum .secondary_btn {
  background: #d3d4d6;
  background: linear-gradient(0deg, #d3d4d6, #a2a3a7 35%, #6b6e74 70%, #d2d2d4);
  border: 1px solid #e5e5e5;
  color: #f0f5ff;
}
.dashboard_panel_platinum .secondary_btn:hover {
  background: linear-gradient(
    180deg,
    #d3d4d6,
    #a2a3a7 35%,
    #6b6e74 70%,
    #d2d2d4
  );
}
.dashboard_panel_platinum .progress_wrap .progress-bar-striped {
  background-color: #808288;
}

/* Diamond Dashboard */
.dashboard_panel_diamond .inner_title {
  color: #e5e5e5;
}
.dashboard_panel_diamond .std_box {
  background: #c7e1ee;
  background: linear-gradient(0deg, #c7e1ee, #f1f6fc 35%, #95c2d9 70%, #c7e1ee);
  border: 2px solid #f4f7ff;
  box-shadow: 0 0 8px 1px rgba(56, 60, 69, 0.7);
  color: #414461;
}
.dashboard_panel_diamond .std_box div:before,
.dashboard_panel_diamond .std_box h3:first-child:after {
  background: #f4f7ff;
}
.dashboard_panel_diamond .std_box div:after,
.dashboard_panel_diamond .std_box h3:nth-child(2):before {
  background: #878aa3;
}
.dashboard_panel_diamond .green_box_wrap_title h2,
.dashboard_panel_diamond .green_box_wrap_title h3,
.dashboard_panel_diamond .purple_box_wrap_title h3,
.dashboard_panel_diamond .purple_box_wrap_title h2 {
  color: #f4f7ff;
}
.dashboard_panel_diamond .rewards_step_list li.active {
  color: #f4f7ff;
}
.dashboard_panel_diamond .rewards_step_list li.active::before {
  background: #f4f7ff;
}
.dashboard_panel_diamond .secondary_btn {
  background: #c7e1ee;
  background: linear-gradient(0deg, #c7e1ee, #f1f6fc 35%, #95c2d9 70%, #c7e1ee);
  border: 1px solid #f4f7ff;
  color: #414461;
}
.dashboard_panel_diamond .secondary_btn:hover {
  background: linear-gradient(
    180deg,
    #c7e1ee,
    #f1f6fc 35%,
    #95c2d9 70%,
    #c7e1ee
  );
}
.dashboard_panel_diamond .progress_wrap .progress-bar-striped {
  background-color: #95c2d9;
}
.package_tab {
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  background: rgba(3, 28, 38, 1);
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid #3ffaf6;
}
.package_tab li.nav-item {
  white-space: nowrap;
}
.package_tab li .tabbtn {
  border-radius: 10px;
  transition: 0.2s all;
  font-size: 16px;
  font-weight: 600;
  padding: 7px 10px;
  min-width: 120px;
  text-transform: uppercase;
}
.package_tab li a {
  text-align: center;
  text-decoration: none;
  border-radius: 0 !important;
  padding: 15px;
}
.package_tab li a.nav-link.active,
.package_tab li a.nav-link:hover {
  background: #063040;
}
.join_now_link {
  display: block;
  font-size: 14px;
  margin: 10px auto 0;
  background: linear-gradient(180deg, #7139d8 0%, rgba(31, 5, 77, 1) 100%);
  border: 1px solid #7139d8;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
}
.select_link {
  display: block;
  font-size: 14px;
  margin: 10px auto 0;
  background: linear-gradient(180deg, #0b6180 0%, rgba(4, 35, 46, 1) 100%);
  border: 1px solid #0b6180;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
}
.select_link_pop {
  display: block;
  font-size: 20px;
  margin: 10px auto 0;
  background: linear-gradient(180deg, #0b6180 0%, rgba(4, 35, 46, 1) 100%);
  border: 1px solid #0b6180;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  /* text-transform: uppercase; */
  color: #fff;
}

/* .select_link:hover,
.join_now_link:hover {
  background: transparent;
  color: #fff;
} */
.select_link.active {
  background: #1a8eb8;
  border: 1px solid #1a8eb8;
  color: #fff;
}
/* .package_tab li+li {
  margin-left: 12px;
} */
.package_tab_fullwidth li {
  width: 100%;
}
.package_dashboard.package_tab_fullwidth li {
  border-radius: 10px;
}
.bronze_tabbtn {
  background: linear-gradient(
    0deg,
    #f2b999 0%,
    #a66641 35%,
    #59341e 70%,
    #f2b077 100%
  );
  border: 1px solid #ebaa73;
  color: #fff;
}
.bronze_tabbtn:hover {
  background: linear-gradient(
    180deg,
    #f2b999 0%,
    #a66641 35%,
    #59341e 70%,
    #f2b077 100%
  );
}
.silver_tabbtn {
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(244, 235, 235, 1) 35%,
    rgba(176, 176, 176, 1) 70%,
    rgba(181, 181, 181, 1) 100%
  );
  border: 1px solid #e5e5e5;
  color: #595959;
}
.silver_tabbtn:hover {
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(244, 235, 235, 1) 35%,
    rgba(176, 176, 176, 1) 70%,
    rgba(181, 181, 181, 1) 100%
  );
  color: #595959;
}
.gold_tabbtn {
  background: linear-gradient(
    0deg,
    rgba(197, 163, 79, 1) 0%,
    rgba(255, 222, 138, 1) 35%,
    rgba(197, 163, 79, 1) 70%,
    rgba(255, 222, 138, 1) 100%
  );
  border: 1px solid #e2c479;
  color: #73460e;
}
.gold_tabbtn:hover {
  background: linear-gradient(
    180deg,
    rgba(197, 163, 79, 1) 0%,
    rgba(255, 222, 138, 1) 35%,
    rgba(197, 163, 79, 1) 70%,
    rgba(255, 222, 138, 1) 100%
  );
  color: #73460e;
}
.platinum_tabbtn {
  background: linear-gradient(
    0deg,
    rgba(211, 212, 214, 1) 0%,
    rgba(162, 163, 167, 1) 35%,
    rgba(107, 110, 116, 1) 70%,
    rgba(210, 210, 212, 1) 100%
  );
  border: 1px solid #e5e5e5;
  color: #f0f5ff;
}
.platinum_tabbtn:hover {
  background: linear-gradient(
    180deg,
    rgba(211, 212, 214, 1) 0%,
    rgba(162, 163, 167, 1) 35%,
    rgba(107, 110, 116, 1) 70%,
    rgba(210, 210, 212, 1) 100%
  );
  color: #f0f5ff;
}
.diamond_tabbtn {
  background: linear-gradient(
    0deg,
    rgba(199, 225, 238, 1) 0%,
    rgba(241, 246, 252, 1) 35%,
    rgba(149, 194, 217, 1) 70%,
    rgba(199, 225, 238, 1) 100%
  );
  border: 1px solid #f4f7ff;
  color: #414461;
}
.diamond_tabbtn:hover {
  background: linear-gradient(
    180deg,
    rgba(199, 225, 238, 1) 0%,
    rgba(241, 246, 252, 1) 35%,
    rgba(149, 194, 217, 1) 70%,
    rgba(199, 225, 238, 1) 100%
  );
  color: #414461;
}
.reward_small_box_opa.rewards_total_box {
  width: 100%;
  overflow: hidden;
}
.reward_small_box_opa.rewards_total_box h2 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  border-bottom: none;
  padding: 5px;
  opacity: 1;
  color: #ebaa73;
  background: linear-gradient(
    180deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
}
.reward_small_box_opa.rewards_total_box > div {
  opacity: 1;
  height: calc(100% - 34px);
}
.reward_small_box_opa.rewards_total_box h3 span {
  display: inline-block;
}
.reward_small_box_opa.rewards_total_box > div h3 {
  font-size: 20px !important;
  font-weight: 700;
}
.banner_info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}
.banner_info > div {
  text-transform: capitalize;
  margin: 0;
  padding: 20px;
  cursor: default;
}
.banner_info > div span {
  font-weight: 600;
}
.banner_info > div .standard_circle {
  height: 20px;
  width: 20px;
}
.banner_info > div.std_slider_box > div {
  margin-bottom: 8px;
}
.dark_purple {
  background: #1f0a43 !important;
  border: 1px solid #553c86 !important;
}
.dark_purple h2 {
  background: linear-gradient(
    180deg,
    rgb(42 11 95) 0%,
    rgb(21 7 47) 100%
  ) !important;
}
.text_{
  text-transform: capitalize;
}
.dashboard_wrapper_inner_right_chg{
  display: flex;
}
.dashboard_wrapper_inner_right_chg .green_box_wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard_wrapper_inner_right_chg .green_box_wrap_body{
  height: 100%;
}
.dashboard_wrapper_inner_right_chg .green_box_wrap_footer {
  padding: 40px 20px;
}
.align-items-unset{
  align-items: unset;
}
.after_none::after{
  display: none;
}
.after_none::before{
  display: none;
}
.after_here::after{
  background: #ebaa73;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.before_here::before{
  background: #5e351e;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.before_blw::before{
  top: unset !important;
  bottom: 2px;
}
.bdr{
  background: linear-gradient(180deg, #ebaa73 50%, #5e351e 50%); 
    display: block;
    height: 2.5px;
}
.dashboard_panel_diamond .bdr{
  background: linear-gradient(180deg, #f4f7ff 50%, #878aa3 50%);
}
.dashboard_panel_gold .bdr{
  background: linear-gradient(180deg, #e0c15c 50%, #f4e979 50%);
}
.dashboard_panel_platinum .bdr{
  background: linear-gradient(180deg, #c6c7c9 50%, #6b6e74 50%);
}
.dashboard_panel_silver .bdr{
  background: linear-gradient(180deg, #b5b5b5 50%, #e5e5e5 50%);
}

.no-border-right::before,
.no-border-right::after{
  display: none !important;
}
/* Media Queries */
@media screen and (max-width: 1399px) and (min-width: 992px) {
  .container {
    max-width: 95%;
  }
  .dashboard_wrapper_inner_left {
    min-width: 260px;
    width: 260px;
  }
  .dashboard_wrapper_inner_right {
    min-width: 515px;
    width: 515px;
  }
  .rewards_step_list li.active::after {
    left: 52px;
  }
  .rewards_step_list li::before {
    width: 98%;
    left: -48px;
  }
  .dashboard_wrapper_right {
    width: 100%;
  }
}
@media screen and (max-width: 1365px) {
  .std_slider_box {
    padding: 25px 13px;
  }
  .dashboard_wrapper {
    flex-wrap: wrap;
  }
  .dashboard_wrapper_right {
    width: 100%;
    margin-top: 30px;
    margin-left: 290px;
  }
  .dashboard_wrapper_left {
    margin-right: 0;
    width: 100%;
  }
  .dashboard_wrapper_inner_right {
    min-width: unset;
    width: calc(100% - 290px);
  }
  .rewards_step_list li::before {
    left: -98px;
    width: 92%;
  }
  .rewards_step_list li.active::after {
    left: 101px;
  }
  .rewards_step > div {
    width: 25%;
    justify-content: center;
  }
  .rewards_step_list li {
    width: 120px;
  }
  .rewards_step_list li::before {
    left: -52px;
  }
  .rewards_step_list li.active::after {
    left: 55px;
  }
}
@media screen and (max-width: 1199px) {
  .package_tab {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 991px) {
  .box_width{
    width: 100%;
  }
  .padd {
    padding-top: 110px;
  }
  .inner_wrapper {
    padding-top: 150px;
  }
  .main_navbar {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    margin-top: 0px;
    z-index: 1000;
  }
  .above_info_box + .main_navbar {
    top: 41px;
  }
  .dashboard_wrapper_inner_left {
    width: 100%;
    min-width: unset;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .dashboard_wrapper_inner_right {
    min-width: unset;
    width: 100%;
  }
  .dashboard_wrapper_right {
    margin-left: 0;
  }
  .dashboard_wrapper_left_top,
  .dashboard_wrapper_left_middle,
  .dashboard_wrapper_left_bottom {
    flex-wrap: wrap;
  }
  body {
    overflow-y: auto !important;
  }
  .standard_wrapper {
    flex-wrap: wrap;
  }
  .standard_wrapper_left,
  .standard_wrapper_right {
    width: 100%;
  }
  .standard_wrapper_left .standard_wrapper_title {
    border-radius: 20px 20px 0 0;
  }
  .standard_wrapper_right .standard_wrapper_title {
    border-radius: 0;
  }
  .standard_wrapper_left {
    border: none;
  }
  .standards_list {
    margin-right: 0;
  }
  .navbar_right {
    flex-wrap: wrap;
  }
  .navbar_right .dropdown {
    display: block;
  }
  .navbar_right button.primary_btn,
  .navbar_right .dropdown {
    width: 100%;
  }
  .referral_div {
    margin-bottom: 30px;
  }
  .footer_panel {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer_panel_left {
    width: 100%;
    margin-bottom: 15px;
  }
  .footer_panel_left p {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .package_tab li .tabbtn {
    min-height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .green_box_flex {
    flex-wrap: wrap;
  }
  .green_box_flex .green_box_wrap {
    width: 100%;
  }
  .rewards_step {
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
  }
  .rewards_step > div span::after {
    display: none;
  }
  ul.rewards_step_list {
    flex-direction: column;
    margin-top: 10px;
  }
  .rewards_step > div p br {
    display: none;
  }
  .green_box_wrap_footer {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: flex-end;
  }
  .rewards_step > div p {
    white-space: nowrap;
  }
  .rewards_step > div {
    width: 100%;
    justify-content: left;
    flex-direction: row-reverse;
    margin-bottom: 20px;
  }
  .rewards_step_list li {
    width: 1px;
    height: 52px;
  }
  .rewards_step_list li.active::after {
    left: 3px;
  }
  .rewards_step_list li::before {
    left: 8px;
    top: -32px;
    height: 45px;
  }
  .rewards_step > div span {
    margin-right: 20px;
  }
  .progress_wrap {
    width: 100%;
  }
  .above_info_box .above_box_flx div + div {
    margin-left: 30px;
  }
  .above_info_box .above_box_flx div p {
    margin-right: 10px;
  }
  .above_info_box .above_box_flx {
    justify-content: flex-start;
  }
  .referral_div {
    flex-wrap: wrap;
    justify-content: center;
  }
  .referral_div img {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .referral_div {
    text-align: center;
  }
  .main_title,
  .page_header h1 {
    font-size: 32px;
  }
  .faq_accordion .accordion-body,
  .main_para,
  .faq_accordion .accordion-button {
    font-size: 16px;
  }
  .primary_modal .btn-close {
    right: -10px;
  }
  .primary_modal .modal-content {
    margin: 20px 0;
  }
  .banner_info {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 575px) {
  .earning_section .nav-link {
    padding: 8px 20px;
    margin-right: 10px;
  }
  .green_box_wrap_title h2,
  .purple_box_wrap_title h2 {
    font-size: 24px;
  }
  .green_box_wrap_title h3,
  .purple_box_wrap_title h3 {
    font-size: 18px;
  }
  .green_box_wrap_body h3 {
    font-size: 24px;
  }
  .secondary_btn_large {
    font-size: 16px;
  }
  .purple_box_wrap_body_pad30 {
    padding: 20px;
  }
  .todo_list li div {
    padding: 5px 5px;
    font-size: 12px;
  }
  .dashboard_panel {
    padding: 15px;
  }
  .standards_list_row h3 {
    font-size: 15px;
  }
  .standard_circle {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
  .standards_list_row h3 span {
    margin-left: 3px;
  }
  .standard_wrapper_body {
    padding: 15px;
  }
}
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  fill: rgb(36, 247, 201) !important;
  margin-left: -32px;
  margin-top: -32px;
  z-index: 99999;
}
.loading::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(245, 242, 242, 0.25);
  z-index: 1500;
  overflow: hidden;
}
.button {
  margin-left: 100px !important;
}

.reward_small_box_wrap_opa {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  gap: 30px;
}
.reward_small_box_opa {
  background: linear-gradient(
    180deg,
    rgba(11, 97, 128, 1) 0%,
    rgba(4, 35, 46, 1) 100%
  );
  border: 1px solid #3ffaf6;
  width: 85px;
  height: 80px;
  border-radius: 10px;
}
.reward_small_box_opa h2 {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #1a8eb8;
  padding: 5px;
  opacity: 0.3;
}
.reward_small_box_opa > div {
  height: calc(100% - 28px);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
}
.reward_small_box_opa h3 {
  font-size: 14px !important;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}
.reward_small_box_opa h3 span {
  display: block;
  font-weight: 300;
}
.earndata_opacity {
  opacity: 0.3;
}

.dash_button,
.package_tab li a.nav-link {
  border-radius: 10px;
  transition: 0.2s all;
  font-size: 18px;
  color: rgba(11, 97, 128, 1);
  font-weight: 600;
  padding: 7px 22px;
  min-width: 120px;
  text-transform: uppercase;
}

.bronze_button {
  background: linear-gradient(
    0deg,
    rgba(242, 185, 153, 1) 0%,
    rgba(166, 102, 65, 1) 32%,
    rgba(89, 52, 30, 1) 54%,
    rgba(242, 176, 119, 1) 100%
  );
  border: 1px solid #ebaa73;
  color: #fff;
}
.bronze_button:hover {
  background: linear-gradient(
    0deg,
    rgba(242, 176, 119, 1) 0%,
    rgba(89, 52, 30, 1) 32%,
    rgba(166, 102, 65, 1) 54%,
    rgba(242, 185, 153, 1) 100%
  );
}
.silver_button {
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(244, 235, 235, 1) 35%,
    rgba(176, 176, 176, 1) 70%,
    rgba(181, 181, 181, 1) 100%
  );
  border: 1px solid #e5e5e5;
  color: #595959;
}
.silver_button:hover {
  background: linear-gradient(
    0deg,
    rgba(181, 181, 181, 1) 0%,
    rgba(176, 176, 176, 1) 35%,
    rgba(244, 235, 235, 1) 70%,
    rgba(245, 245, 245, 1) 100%
  );
  color: #595959;
}
.gold_button {
  background: linear-gradient(
    0deg,
    rgba(197, 163, 79, 1) 0%,
    rgba(255, 222, 138, 1) 35%,
    rgba(197, 163, 79, 1) 70%,
    rgba(255, 222, 138, 1) 100%
  );
  border: 1px solid #e2c479;
  color: #73460e;
}
.gold_button:hover {
  background: linear-gradient(
    0deg,
    rgba(250, 248, 132, 1) 0%,
    rgba(192, 126, 43, 1) 35%,
    rgba(252, 254, 136, 1) 70%,
    rgba(192, 126, 44, 1) 100%
  );
  color: #73460e;
}
.platinum_button {
  background: linear-gradient(
    0deg,
    rgba(211, 212, 214, 1) 0%,
    rgba(162, 163, 167, 1) 35%,
    rgba(107, 110, 116, 1) 70%,
    rgba(210, 210, 212, 1) 100%
  );
  border: 1px solid #e5e5e5;
  color: #f0f5ff;
}

.platinum_button:hover {
  background: linear-gradient(
    0deg,
    rgba(210, 210, 212, 1) 0%,
    rgba(107, 110, 116, 1) 35%,
    rgba(162, 163, 167, 1) 70%,
    rgba(211, 212, 214, 1) 100%
  );
  color: #f0f5ff;
}

.diamond_button {
  background: linear-gradient(
    0deg,
    rgba(199, 225, 238, 1) 0%,
    rgba(241, 246, 252, 1) 35%,
    rgba(149, 194, 217, 1) 70%,
    rgba(199, 225, 238, 1) 100%
  );
  border: 1px solid #f4f7ff;
  color: #414461;
}
.checkkkk {
  justify-content: center !important;
}
.extraforcenetr {
  text-align: center !important;
}

.diamond_button:hover {
  background: linear-gradient(
    0deg,
    rgba(199, 225, 238, 1) 0%,
    rgba(149, 194, 217, 1) 35%,
    rgba(241, 246, 252, 1) 70%,
    rgba(199, 225, 238, 1) 100%
  );
  color: #414461;
}

.earning_section .dropdown-toggle::after {
  margin-left: 0.755em;
  top: 11px;
  position: relative;
}

.select_color {
  background: #376d6e;
}

#exit_popup {
  background: #000000d1;
}


  